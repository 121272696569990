import { Collapse } from "antd";
import logo from "../logo.png"
const { Panel } = Collapse;

const Navbar = () => {
  
  return (
    <nav>
    <nav class="border-gray-200 bg-themegrey">
        <div class="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl p-4">
            <a href="https://eaglesecuritybd.com" class="flex items-center">
                <img src={logo} class="h-20 mr-3" alt="Eagle Security Logo" />
                <span class="self-center text-l mb:text-4xl font-semibold whitespace-nowrap dark:text-white">Eagle Security and Services Ltd</span>
            </a>
            <div class="flex items-center hidden md:block">
                <a href="tel:+88028141184" class="mr-6 text-sm text-gray-500 dark:text-white hover:underline">+88 02-8141184</a>
                <a href="mailto:info@eaglesecuritybd.com" class="text-sm text-blue-600 dark:text-blue-500 hover:underline">info@eaglesecuritybd.com</a>
            </div>
        </div>
    </nav>
    <Collapse bordered={false} style={{borderRadius:0}} className="bg-themeblue  block md:hidden"> 
      <Panel header="Menu" className="text-white" style={{color:"white !important"}} key="1">
      <nav class="bg-themeblue">
        <div class="max-w-screen-xl px-4 py-3 mx-auto">
            <div class="flex items-center">
                <ul class="flex flex-col md:flex-row font-medium mt-0 mr-6 space-y-4 md:space-y-0 md:space-x-8 text-sm">
                    <li>
                        <a href="home" class="text-white hover:underline" aria-current="page">Home</a>
                    </li>
                    <li>
                        <a href="profile" class="text-white hover:underline">Our Profile</a>
                    </li>
                    <li>
                        <a href="about" class="text-white hover:underline">Who Are We</a>
                    </li>
                    <li>
                        <a href="services" class="text-white hover:underline">Our Services</a>
                    </li>
                 
                    <li>
                        <a href="gallery" class="text-white hover:underline">Gallery</a>
                    </li>
                    <li>
                        <a href="contact" class="text-white hover:underline">Contact Us</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
      </Panel>
    </Collapse>
    <nav class="bg-themeblue hidden md:block">
        <div class="max-w-screen-xl px-4 py-3 mx-auto">
            <div class="flex items-center">
                <ul class="flex flex-col md:flex-row font-medium mt-0 mr-6 space-y-4 md:space-y-0 md:space-x-8 text-sm">
                    <li>
                        <a href="home" class="text-white hover:underline" aria-current="page">Home</a>
                    </li>
                    <li>
                        <a href="profile" class="text-white hover:underline">Our Profile</a>
                    </li>
                    <li>
                        <a href="about" class="text-white hover:underline">Who Are We</a>
                    </li>
                    <li>
                        <a href="services" class="text-white hover:underline">Our Services</a>
                    </li>
                    
                    <li>
                        <a href="gallery" class="text-white hover:underline">Gallery</a>
                    </li>
                    <li>
                        <a href="contact" class="text-white hover:underline">Contact Us</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</nav>

  );
};

export default Navbar;