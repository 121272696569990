import { collection, getDocs } from "firebase/firestore"
import { useEffect, useState } from "react"
import { db } from "../../components/firebaseConfig"



const Services = () =>{ 
    // /Website/ServicePage/Contents
    const [services, setservices] = useState([])
	const getservices = ()=>{
		setservices([])
	  getDocs(collection(db,"Website/ServicePage/Contents")).then((snap)=>{
		snap.forEach((v)=>{
		  setservices(prev=> prev.filter((temp,i)=> String(v.title).toLowerCase()==String(temp.title).toLowerCase()).length <1 ?[...prev,{text:v.data().text,title:v.data().title}] : [...prev] )
		})
	  })
	}
	useEffect(() => {
	  getservices()
	}, [])
    return(
        <div class="py-16 bg-gray-50 overflow-hidden">
        <div class="container m-auto px-6 space-y-8 text-gray-500 md:px-12">
            <div>
                <span class="text-gray-600 text-lg font-semibold">Main services</span>
                <h2 class="mt-4 text-2xl text-gray-900 font-bold md:text-4xl">A Security company that takes care about everything</h2>
            </div>
            <div class="mt-16 grid border divide-x divide-y rounded-xl overflow-hidden sm:grid-cols-2 lg:divide-y-0 lg:grid-cols-3 xl:grid-cols-4">
                {services.map((v,i)=> i<3 && <div key={i} class="relative group bg-white transition hover:z-[1] hover:shadow-2xl">
                    <div class="relative p-8 space-y-8">
                        <div class="space-y-2">
                            <h5 class="text-xl text-gray-800 font-medium transition group-hover:text-yellow-600">{v.title}</h5>
                            <p class="text-sm text-gray-600">{String(v.text).substring(0,200)}...</p>
                        </div>
                        <a href="services" class="flex justify-between items-center group-hover:text-yellow-600">
                            <span class="text-sm">Read more</span>
                            <span class="-translate-x-4 opacity-0 text-2xl transition duration-300 group-hover:opacity-100 group-hover:translate-x-0"href="services">Learn More</span>
                        </a>
                    </div>
                </div> )}
                <div class="relative group bg-gray-100 transition hover:z-[1] hover:shadow-2xl lg:hidden xl:block">
                    <div class="relative p-8 space-y-8 border-dashed rounded-lg transition duration-300 group-hover:bg-white group-hover:border group-hover:scale-90">
                        <img src="https://tailus.io/sources/blocks/stacked/preview/images/avatars/metal.png" class="w-10" width="512" height="512" alt="burger illustration"/>
                        
                        <div class="space-y-2">
                            <h5 class="text-xl text-gray-800 font-medium transition group-hover:text-yellow-600">More Services</h5>
                            <p class="text-sm text-gray-600">Learn about all the other services we provide</p>
                        </div>
                        <a href="services" class="flex justify-between items-center group-hover:text-yellow-600">
                            <span class="text-sm">Read more</span>
                            <span class="-translate-x-4 opacity-0 text-2xl transition duration-300 group-hover:opacity-100 group-hover:translate-x-0"href="services">Learn More</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}


export default Services