import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { collection, getDocs, getDoc, doc } from "firebase/firestore"; 

import reportWebVitals from './reportWebVitals';
import { db } from './components/firebaseConfig';

const root = ReactDOM.createRoot(document.getElementById('root'));
export const websiteData = {}
getDocs(collection(db, "Website")).then((website)=>{
 
  website.docs.forEach(element => {

    websiteData[`${element.data().pageName}`] = element.data()

  });

  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
  

})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
