import { Image } from "antd"
import { collection, getDocs } from "firebase/firestore"
import { useEffect, useState } from "react"
import { db } from "../../components/firebaseConfig"

const Gallery = () => {

  const [images, setimages] = useState([])
  useEffect(() => {
    getDocs(collection(db, "Website/Gallery/Images")).then((snap) => {
      setimages(snap.docs);
    })
  }, [])
  



    return (

        <div className="flex p-5">
   <div className="masonry gap-1 p-3">
    {images.map((v,i)=>{
      const {image} = v.data()
      return(
        <div key={i} className="p-3">
          <Image
            className="h-auto max-w-full rounded-lg"
            src={image}
            alt=""
          />
        </div>
      )
    })}
    
  </div>
 
</div>

    )
}


export default Gallery