import { Button, Card, Col, Image, Row } from "antd"
import { collection, getDocs } from "firebase/firestore"
import { useEffect, useState } from "react"
import { db } from "../../components/firebaseConfig"




const Certificates = () => {
    const [certificates, setcertificates] = useState([])
    useEffect(()=>{
      setcertificates([])
      getDocs(collection(db,"Website/Certifications/Images")).then((snap)=>{
        snap.forEach((doc)=>{
          setcertificates((prev)=> prev.filter((v)=> String(v).toLowerCase().trim()==String(doc.data().image).toLowerCase().trim()).length>0 ? prev : [...prev,doc.data().image])
        })
        
      })
    },[])
    return(
      <div className="w-2/3">
          <Row>
        <Col className="ml-auto mr-auto" md="8">
        <h2 className="text-2xl text-gray-700 font-bold md:text-4xl">
   Our Certificates
  </h2>
  <br></br>
  <br></br>
          <Row gutter={16}>
            {certificates.map((v,i)=> i<3 && <Col span={8} key={i}>
<Card title="" bordered={false}>
  <Image src={v}/>
</Card>
</Col>)}
{/* https://firebasestorage.googleapis.com/v0/b/eagle-security-bangladesh.appspot.com/o/Certificates%2FEagle%20Security%2F16.jpg?alt=media&token=e6c34a55-e7fd-4425-9c12-790e61e74daa */}
{/* <Col span={8}>
<Card title="" bordered={false}>
  <Image src="https://firebasestorage.googleapis.com/v0/b/eagle-security-bangladesh.appspot.com/o/Certificates%2FEagle%20Security%2F20.jpg?alt=media&token=3a7c8ed1-1c32-49a6-8191-3b289184b07d"/>
</Card>
</Col>
<Col span={8}>
<Card title="" bordered={false}>
  <Image src="https://firebasestorage.googleapis.com/v0/b/eagle-security-bangladesh.appspot.com/o/Certificates%2FEagle%20Security%2F15.jpg?alt=media&token=3da7dc0b-8a8e-40ed-ac33-6883e39b7de4"/>
  
</Card>
</Col> */}
</Row>
<br></br>
<Button onClick={()=>{
window.location = "certificates"
}}>View more</Button>
          <br />
         
        </Col>
      </Row>
      </div>
    )
}

export default Certificates