// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCSi6CsEgAd4dPncaxMC8Ws5SHt5_VTtco",
  authDomain: "eagle-security-bangladesh.firebaseapp.com",
  projectId: "eagle-security-bangladesh",
  storageBucket: "eagle-security-bangladesh.appspot.com",
  messagingSenderId: "1076201514987",
  appId: "1:1076201514987:web:cf0cba4050cf601f531bae",
  measurementId: "G-3J890GKZVY"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
// export const auth = getAuth(firebaseConfig);
export const db = getFirestore(app);
const analytics = getAnalytics(app);