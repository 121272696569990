import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../components/firebaseConfig";

const Partners = () => {
  const [partners, setPartners] = useState([]);
  
  useEffect(() => {
    getDocs(collection(db, "Website/Homepage/Partners")).then((snap) => {
      setPartners(snap.docs);
    })
  }, [])
  

  return (
    <div className="container">
    
    <center>
    <section className="customer-logos slider " style={{backgroundColor:"#fff"}}>

<div className="py-16 bg-white">
<div className="container m-auto px-6 space-y-8 ">
<div className="m-auto text-center lg:w-7/12">
  <h2 className="text-2xl text-gray-700 font-bold md:text-4xl">
    Your favorite companies are our partners.
  </h2>
</div>
<div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4">
{partners.map((xed)=>{
                  const {image} = xed.data()
                  return(
                  <div className="p-4">
                      <img src={image} width={`${window.innerHeight > window.innerWidth ? "50px" : "150px"}`} />
                  </div>
                  )
                }
                
            
       
                )
              }
 
</div>
</div>
</div>
  
</section>
    </center>
    </div>
  );
};

export default Partners;