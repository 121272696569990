import p1 from './Captain Shahed Ullah Chisti,Managing Dricetor Chif Executive Officer.jpg'
import p2 from './Dr. Mahfuz Ullah Chisti, Partner .jpg'
import p3 from './Zeenat Shohana Chisti, Partner.jpg'
import p4 from './Asma Akter Director.JPG'
import { Image } from 'antd'

const Profile = () => {


    return (


        <section className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-4 py-12">
  <div className="text-center pb-12">
    <h2 className="text-base font-bold text-indigo-600">
      We have the best equipment
    </h2>
    <h1 className="font-bold text-3xl md:text-4xl lg:text-5xl font-heading text-gray-900">
      Check our awesome team members
    </h1>
  </div>
  <div className="w-full bg-white rounded-lg p-12 flex flex-col justify-center items-center">
      <div className="mb-8">
        <Image
          className="object-center object-cover  h-36 w-36"
          src={p1}
          alt="photo"
        />
      </div>
      <div className="text-center">
        <p className="text-xl text-gray-700 font-bold mb-2">Captain Shahed Ullah Chisti
</p>
        <p className="text-base text-gray-400 font-normal">Managing Director & Chief Executive Officer

</p>
      </div>
    </div>
  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-6">
  <div className="w-full bg-white rounded-lg p-12 flex flex-col justify-center items-center">
      <div className="mb-8">
        <Image
           className="object-center object-cover  h-36 w-36"
          src={p4}
          alt="photo"
        />
      </div>
      <div className="text-center">
        <p className="text-xl text-gray-700 font-bold mb-2">Asma Akter
</p>
        <p className="text-base text-gray-400 font-normal">Direector</p>
      </div>
    </div>
   
    <div className="w-full bg-white rounded-lg p-12 flex flex-col justify-center items-center">
      <div className="mb-8">
        <Image
           className="object-center object-cover  h-36 w-36"
          src={p2}
          alt="photo"
        />
      </div>
      <div className="text-center">
        <p className="text-xl text-gray-700 font-bold mb-2">Dr. Mahfuz Ullah Chisti
</p>
        <p className="text-base text-gray-400 font-normal">Partner</p>
      </div>
    </div>
    <div className="w-full bg-white rounded-lg p-12 flex flex-col justify-center items-center">
      <div className="mb-8">
        <Image
           className="object-center object-cover  h-36 w-36"
          src={p3}
          alt="photo"
        />
      </div>
      <div className="text-center">
        <p className="text-xl text-gray-700 font-bold mb-2">Zeenat Shohana Chisti
</p>
        <p className="text-base text-gray-400 font-normal">Partner</p>
      </div>
    </div>
   

  </div>
</section>

    )
}


export default Profile