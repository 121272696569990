import { useEffect, useState } from 'react'
import p1 from './p1.jpg'
import p2 from './p2.jpg'
import p3 from './p3.jpg'
import p4 from './p4.jpeg'
import p5 from './p5.jpg'
import { collection, getDocs } from 'firebase/firestore'
import { db } from '../../components/firebaseConfig'

const Services = () => {
    const [services, setservices] = useState([])

    useEffect(() => {
      getDocs(collection(db,"Website/ServicePage/Contents")).then((snap)=>{
        snap.forEach((doc)=>{
          setservices((prev)=> prev.filter((v)=> String(v.title).toLowerCase().trim()==String(doc.data().title).toLowerCase().trim()).length>0 ? prev : [...prev,doc.data()])
        })
      })
    }, [])
    

    return(
<div>
<section className="bg-white dark:bg-gray-900">
<div className="grid grid-cols-6 col-span-2   gap-2  ">
  <div className=" overflow-hidden rounded-xl col-span-3 max-h-[14rem]">
    <img
      className="h-full w-full object-cover "
      src={p1}
      alt=""
    />
  </div>
  <div className=" overflow-hidden rounded-xl col-span-3 max-h-[14rem]">
    <img
      className="h-full w-full object-cover  "
      src={p2}
      alt=""
    />
  </div>
  <div className=" overflow-hidden rounded-xl col-span-2 max-h-[10rem]">
    <img
      className="h-full w-full object-cover "
      src={p3}
      alt=""
    />
  </div>
  <div className=" overflow-hidden rounded-xl col-span-2 max-h-[10rem]">
    <img
      className="h-full w-full object-cover "
      src={p4}
      alt=""
    />
  </div>
  <div className="relative overflow-hidden rounded-xl col-span-2 max-h-[10rem]">
    <div className="text-white text-xl absolute  bg-slate-900/80 flex justify-center items-center">
     
    </div>
    <img
      className="h-full w-full object-cover "
      src={p5}
      alt=""
    />
  </div>
</div>

  
</section>


{services.splice(0).reverse().map((v,i)=><div key={i} className="container px-6 py-10 mx-auto">
    
    <div className="mt-8 lg:-mx-6 ">
      
      <div className="mt-6  lg:mt-0 lg:mx-6 ">
        <p className="text-sm text-blue-500 uppercase">Services</p>
        <a
          href="#"
          className="block mt-4 text-2xl font-semibold text-gray-800 hover:underline dark:text-white md:text-3xl"
        >
        {v.title}

        </a>
        <p className="mt-3 text-sm text-gray-500 dark:text-gray-300 md:text-sm">
        {v.text}
        </p>
        
        
      </div>
    </div>
  </div>)}
{/* <section className="bg-white dark:bg-gray-900">
  <div className="container px-6 py-10 mx-auto">
    
    <div className="mt-8 lg:-mx-6 lg:flex lg:items-center">
      
      <div className="mt-6  lg:mt-0 lg:mx-6 ">
        <p className="text-sm text-blue-500 uppercase">Services</p>
        <a
          href="#"
          className="block mt-4 text-2xl font-semibold text-gray-800 hover:underline dark:text-white md:text-3xl"
        >
          Gunman Services

        </a>
        <p className="mt-3 text-sm text-gray-500 dark:text-gray-300 md:text-sm">
        We are one of the most prominent Gunman Service Providers in Dhaka than some others. We are focused on the best expectations of Homecare and expert morals for our organization. Just as our customers, for our organization and staff to justify the admiration of the local area and our customers. The workers in our association are talented, experienced, and delicate. Armed Security guards in our organization are prepared to offer 24-hour support.
We have as of now given Gunman Service to many enormous organizations, our talented Gunmen are helping those organizations better meet their expectations. So, they have no bad things to say against us however they are thankful to us. This is the means by which we win the hearts of our customers by giving top-notch Gunman Services. Presently we are prepared to satisfy your ideal assumption, to take great Gunman Services, kindly reach us by simply making a phone call.
Generally, Ex-police officers are assigned as armed security guard. Since Army members also have gun skills, retired Army personnel are also considered as gunmen. Many of us think that no one can become a gunman except an army member, but that is not the case. Anyone, in general, can be a gunman. However, in that case, he must have a firearms license. In that case, he was appointed as a gunman only after the police verified and informed the police station.
        </p>
        
        
      </div>
    </div>
  </div>
</section>




<section className="bg-white dark:bg-gray-900">
  <div className="container px-6 py-10 mx-auto">
    
    <div className="mt-8 lg:-mx-6 lg:flex lg:items-center">
      
      <div className="mt-6  lg:mt-0 lg:mx-6 ">
        <p className="text-sm text-blue-500 uppercase">Services</p>
        <a
          href="#"
          className="block mt-4 text-2xl font-semibold text-gray-800 hover:underline dark:text-white md:text-3xl"
        >
      Cleaning Services
        </a>
        <p className="mt-3 text-sm text-gray-500 dark:text-gray-300 md:text-sm">
        Eagle Security and Services is one of the leading cleaning service providers in Bangladesh. So if you looking for any reliable, trustworthy, and also professional house cleaning services company near you! Congratulations, you are in the right place.
Professional cleaning service in Bangladesh can be of tremendous help to anyone who’s too busy or not good enough at cleaning to do it themselves, but you should know that not all such services are the same. So how do you know which one to choose? Look no further than XYZ Cleaning Service! We have been in the industry since 2016 and have worked with businesses and homeowners in every major city and town in the country, so we have experience with a wide variety of cleaning challenges and solutions.
        </p>
        
        
      </div>
    </div>
  </div>
</section>





<section className="bg-white dark:bg-gray-900">
  <div className="container px-6 py-10 mx-auto">
    
    <div className="mt-8 lg:-mx-6 lg:flex lg:items-center">
      
      <div className="mt-6  lg:mt-0 lg:mx-6 ">
        <p className="text-sm text-blue-500 uppercase">Services</p>
        <a
          href="#"
          className="block mt-4 text-2xl font-semibold text-gray-800 hover:underline dark:text-white md:text-3xl"
        >
Security For Event Management
        </a>
        <p className="mt-3 text-sm text-gray-500 dark:text-gray-300 md:text-sm">
        The event Security Management team serves you the highest measure of security while organizing an event. The target is to take care of the safety and security of the event venue and the people in the event. This security service glorifies the experience of the guests in the event and empowers the guests to take control of the most precious moments.
Some aspects should be followed while taking any security measures in an event venue. Eagle Security and Services always obtains all these aspects launched and finishes the event successfully without any hazard.
        </p>
        
        <p className="mt-3 text-sm text-gray-500 dark:text-gray-300 md:text-sm font-bold">
        These are the security aspects that Eagle Security and Services always follows:
  </p>
        
        <p className="mt-3 text-sm text-gray-500 dark:text-gray-300 md:text-sm">
       1. Our team always collects a guest list for a private program before the event starts. After the guests arrive, they compare the present list with the enlisted guest list to check if any unknown figures can identify. <br></br> <br></br>
2. Nowadays, if you want to make an event safe and secure for all of your wants to make it successful, and most importantly if you want to make your guests comfortable and happy, you should hire us. Our security agency will assure you that no unwanted people are present in the event to spoil it. <br></br> <br></br>
3. Sometimes, in a certain situation, the event can get out of hand and an accident can happen. Max Secure Limited will assure your safety by handling the situation through our expert security team. <br></br> <br></br>
        
        </p>
      </div>
    </div>
  </div>
</section> */}

</div>

    )
}

export default Services