
import './App.css';
import Navbar from './components/navbar';
import Homepage from './pages/Homepage/Homepage';
import Footer from './components/footer';
import About from './pages/About/About';
import Profile from './pages/Profile/Profile';
import Services from './pages/Services/Services';
import Gallery from './pages/Gallery/Gallery';
import Contact from './pages/Contact/Contact';

const path = window.location.pathname.split('/')
path.splice(0,1)

const pages = {
  "" : <Homepage/>,
  "home" : <Homepage/>,
  "index" : <Homepage/>,
  "about" : <About/>,
  "profile" : <Profile/>,
  "services" : <Services/>,
  "gallery": <Gallery/>,
  "contact" : <Contact/>
}


function App() {
  return (
    <div className="App">
  <Navbar/>

  {pages[path[0].toLowerCase()]}

  <Footer/>
    </div>
  );
}

export default App;
