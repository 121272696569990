import HomeCarousel from "./Carousel"
import Certificates from "./Certificates"
import History from "./History"
import ClientSay from "./ClientSay"
import Partners from "./Partners"
import Features from "./Features"
import Services from "./Services"
import License from "./Licecnse"




const Homepage = () =>{

    return(

        <div>
            <HomeCarousel/>
            <div className="bg-themeblue">
<center>
    <br></br>
    <br></br>
    <h1 className="text-3xl font-bold text-white uppercase">
    leading security service providers
    </h1>
    <h1 className="text-2xl font-bold text-white uppercase">
    in Bangladesh
    </h1>
    
    <br></br>
    <br></br>
   
</center>
            </div>
            <div className="bg-white">
<center>
    
<br></br>

<br></br>
  <br></br>
<h2 className="text-2xl text-gray-700 font-bold md:text-4xl">
    About us
  </h2>
    
    <br></br>
    <p className="w-4/5 md:w-3/5">
    Eagle Security and Services is one of the leading security service providers, operating in Bangladesh since 2002. The foundation of our services is our fast growing good will and reputation which we have earned though our highly dedicated, well organized management and trained security guards. A number of professional Armed Forces Services personnel are the core asset of our services who are working and monitoring our services round the clock for smooth functioning of our organization. At present we are providing security service to no’s of Private Bank, Financial Organization, University, Hospitals, Engineering & Construction organizations, Real Estate & Developing companies with good reputation.
    </p>
</center>
            </div>
          <center>
          <br></br>
          <br></br>
  <br></br>
  <br></br>
  <License/>
  <br></br>
  <br></br>
<center>  <Features/></center>
<center>  <Services/></center>
          <br></br>
  <br></br>
  <History/>
  <br></br>
  <br></br>
          <Certificates/>
  <br></br>
  <br></br>
          <ClientSay/>
          <Partners/>
          </center>
        </div>


    )
}

export default Homepage