import { collection, getDocs } from "firebase/firestore"
import { useEffect, useState } from "react"
import { db } from "../../components/firebaseConfig"

const Features = () => {
	const [features, setfeatures] = useState([])
	const getfeatures = ()=>{
	  getDocs(collection(db,"Website/Homepage/WhyChoose")).then((snap)=>{
		setfeatures(snap.docs)
	  })
	}
	useEffect(() => {
	  getfeatures()
	}, [])

    return(
        <div className="bg-themeblue">
            
        <div class="p-4 text-gray-600  w-3/4">
            <br></br>
            <br></br>
	<h1 class="mb-8 text-center text-3xl font-bold text-white">Why Choose us </h1>

	<center>
    <ul class="grid place-content-center sm:grid-cols-2 gap-8">
		{features.map((data,i)=> {
			const v = data.data()
			return (<li class="flex" key={i}>
			<div class="px-4 text-5xl font-extralight text-indigo-700">{Number(i+1).toString().padStart(2,'0')}.</div>
			<div>
				<p class="max-w-xs py-2 text-sm text-white">{v.text}
</p>
			</div>
		</li>)})}
		
		{/* <li class="flex">
			<div class="px-4 text-5xl font-extralight text-indigo-700">02.</div>
			<div>
				<p class="max-w-xs py-2 text-sm text-white">
                Retired army personnel with good reputation, knowledge and experience regarding security aspects run this organization.

				</p>
			</div>
		</li>
		<li class="flex">
			<div class="px-4 text-5xl font-extralight text-indigo-700">03.</div>
			<div>
				<p class="max-w-xs py-2 text-sm text-white">We have well established and decorated office complex in business area near Farm gate in Dhaka City.

				</p>
			</div>
		</li>
		<li class="flex">
			<div class="px-4 text-5xl font-extralight text-indigo-700">04.</div>
			<div>

				<p class="max-w-xs py-2 text-sm text-white">We have well established and decorated office complex in business area near Farm gate in Dhaka City.

				</p>
			</div>
		</li>
		<li class="flex">
			<div class="px-4 text-5xl font-extralight text-indigo-700">05.</div>
			<div>
				<p class="max-w-xs py-2 text-sm text-white">We recruit the security guards very carefully following the process of investigation and maintaining documents.

				</p>
			</div>
		</li>
		<li class="flex">
			<div class="px-4 text-5xl font-extralight text-indigo-700">06.</div>
			<div>
				<p class="max-w-xs py-2 text-sm text-white">We recruit the security guards very carefully following the process of investigation and maintaining documents.

				</p>
			</div>
		</li> */}
		
	</ul>
    </center>
</div>
<br></br>
            <br></br>
        </div>
    )
}

export default Features