import { useEffect, useState } from "react"
import etv from "./etv.png"
import issb from './issb.png'
import { collection, getDocs } from "firebase/firestore"
import { db } from "../../components/firebaseConfig"


const ClientSay = () => {
    const [reviews, setreviews] = useState([])
    useEffect(() => {
      getDocs(collection(db,"Website/Homepage/Reviews")).then((snap)=>{
        setreviews(snap.docs)
      })
    }, [])
    


    return(

        <div >
              <h2 className="text-2xl text-black font-bold md:text-4xl">
   What our clients say
  </h2>
  <br></br>
<div class="grid mb-8 border border-gray-200  shadow-sm md:mb-12 md:grid-cols-2">
    {reviews.map((v,i)=> {
        const data = v.data()
        return(
            <figure key={i} class="flex flex-col items-center justify-center p-8 text-center  border-b md:rounded-t-none md:rounded-tl-lg md:border-r bg-gray-800 border-gray-700">
            <blockquote class="max-w-2xl mx-auto mb-4 text-gray-500 lg:mb-8">
                <h3 class="text-lg font-semibold text-white">{data.title}</h3>
                <p class="my-4">"{data.text}"</p>
            </blockquote>
            <figcaption class="flex items-center justify-center space-x-3">
                <img class="rounded-full w-9 h-9" src={data.image} alt="profile picture" />
                <div class="space-y-0.5 font-medium text-white text-left">
                    <div>{data.name}</div>

                <p className="text-xs text-gray-300 mt-2">{data.job}</p>
                </div>
            </figcaption>    
        </figure>
        )
   })}
    
    {/* <figure class="flex flex-col items-center justify-center p-8 text-center  border-b border-gray-200  bg-gray-800 border-gray-700">
        <blockquote class="max-w-2xl mx-auto mb-4 text-gray-500 lg:mb-8 text-gray-400">
            <h3 class="text-lg font-semibold text-white">Cleaning Services</h3>
            <p class="my-4">"I recently hired a cleaning service for my home, and I am extremely satisfied with the results. The cleaning team was professional, efficient, and paid great attention to detail. They arrived on time and were respectful of my property throughout the entire process."</p>
        </blockquote>
        <figcaption class="flex items-center justify-center space-x-3">
            <img class="rounded-full w-9 h-9" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/joseph-mcfall.png" alt="profile picture" />
            <div class="space-y-0.5 font-medium text-white text-left">
                <div>Rafique Shikder</div>
                <div class="text-sm text-gray-500 text-gray-400">HR Admin at Salamot Enterprise</div>
            </div>
        </figcaption>    
    </figure>
    <figure class="flex flex-col items-center justify-center p-8 text-center  border-b border-gray-200  md:border-b-0 md:border-r bg-gray-800 border-gray-700">
        <blockquote class="max-w-2xl mx-auto mb-4 text-gray-500 lg:mb-8 text-gray-400">
            <h3 class="text-lg font-semibold text-white">Security Guard Services</h3>
            <p class="my-4">"The guards were well-versed in security protocols and procedures, and they displayed excellent situational awareness. They promptly responded to any suspicious activities or incidents, effectively diffusing potentially problematic situations. Their quick thinking and ability to handle difficult situations with calmness and professionalism were commendable."</p>
        </blockquote>
        <figcaption class="flex items-center justify-center space-x-3">
            <img class="rounded-full w-9 h-9" src={issb} alt="profile picture" />
            <div class="space-y-0.5 font-medium text-white text-left">
                <div>ISSB</div>
            </div>
        </figcaption>    
    </figure>
    <figure class="flex flex-col items-center justify-center p-8 text-center    bg-gray-800 border-gray-700">
        <blockquote class="max-w-2xl mx-auto mb-4 text-gray-500 lg:mb-8">
            <h3 class="text-lg font-semibold text-white">Security for Event Management</h3>
            <p class="my-4">"During the event, the security team was highly visible and maintained a strong presence, which helped deter any potential security threats. They efficiently managed access control, ensuring that only authorized personnel were allowed entry. Bag checks and metal detector screenings were conducted smoothly, without causing any inconvenience to attendees."</p>
        </blockquote>
        <figcaption class="flex items-center justify-center space-x-3">
            <img class="rounded-full w-9 h-9" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/joseph-mcfall.png" alt="profile picture" />
            <div class="space-y-0.5 font-medium text-white text-left">
                <div>Ashfi Khandakar</div>
                <div class="text-sm text-gray-500">Ashfi Decorators and Event Managers</div>
            </div>
        </figcaption>    
    </figure> */}
</div>

        </div>
    )
}

export default ClientSay