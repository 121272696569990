import { collection, getDocs } from "firebase/firestore"
import { useEffect, useState } from "react"
import { db } from "../../components/firebaseConfig"


const About = ()=>{
  const [abouts, setabouts] = useState([])

  useEffect(() => {
    getDocs(collection(db,"/Website/AboutPage/Contents")).then((snap)=>{
      snap.forEach((doc)=>{
        setabouts((prev)=> prev.filter((v)=> String(v.title).toLowerCase().trim()==String(doc.data().title).toLowerCase().trim()).length>0 ? prev : [...prev,doc.data()])
    })
  })
  
  }, [])
  
    return(

       <div>
        <div className="p-8">
  <div className="bg-white p-4 rounded-lg shadow-xl py-8 mt-12">
    <h4 className="text-4xl font-bold text-gray-800 tracking-widest uppercase text-center">
      About Us
    </h4>
    <p className="text-center text-gray-600 text-sm mt-2">
      Who are we?
    </p>
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 xl:gap-12 px-2 xl:px-12 mt-4">
      {abouts.map((v,i)=> <div key={i} className="flex space-x-8 mt-8">
      
      <div>
        <h4 className="text-xl font-bold text-gray-700">
          {v.title}
        </h4>
        <p className="text-gray-600 my-2">
        {v.text}
        </p>
        
      </div>
    </div>)}
      
      {/* <div className="flex space-x-8 mt-8">
       
        <div>
          <h4 className="text-xl font-bold text-gray-700">
          Our Promise
          </h4>
          <p className="text-gray-600 my-2">
          Clients of Eagle Security and Services will have the highest 
standard of manned guarding security service at an unbeatable price. 
We deliver all that you would expect form a client focused firm, after all 
your security is our main priority. 
          </p>
      
        </div>
      </div>
      <div className="flex space-x-8 mt-8">
        
        <div>
          <h4 className="text-xl font-bold text-gray-700">
            Our methods
          </h4>
          <p className="text-gray-600 my-2">
          Eagle Security and Services is always available to discuss and analyze your security problems. We will carry out full 
security audits to identify the strengths and weaknesses in your premises and advice on adequate security arrangements 
that we feel are appropriate for your needs. Here Eagle Security and Services we fully appreciate that when you are 
selecting a security supplier, you need complete confidence that the chosen company will not only match your 
requirements and maintain the highest service levels. This is why we place such importance on building close relationships 
with our clients in order to carry out comprehensive needs assessment from each venue
Make Eagle Security and Services your first choice for peace of mind, safety and security.
"At Eagle Security and Services we work for you - We work with you - That’s Our Policy.
          </p>
      
        </div>
      </div>
      <div className="flex space-x-8 mt-8">
       
        <div>
          <h4 className="text-xl font-bold text-gray-700">
            Mission statement
          </h4>
          <p className="text-gray-600 my-2">
          Our Mission is to put your mind at ease by providing top-notch, premium, and unsurpassed security services for your 
company. Our security personnel are attentive, vigilant, and ready to respond to any type of Emergencies or potentially 
22
dangerous situations. Our formula for success is hiring the right person for the job.
We are not a large corporate security firm; however, we have a sufficient number of experienced and adequately trained 
security personnel to accommodate your security needs. When you select Eastern Atlas Limited as your security provider, 
First we analyze and evaluate the
needs and requirements of the client and design Standard Operating Procedures for each individual account. Second, we 
review the overall characteristics, and experience of each of our security personnel and select the ones most sufficiently 
trained and qualified to provide the services, and ultimately fulfill the needs of the client.
Customer Satisfaction is our top priority. We are here to provide a professional security service for your company. and 
to put your mind at ease by delivering top-level protection services to your company.
Our Mission is to provide a level of protective services measured by tangible results; To work tirelessly day and night 
safeguarding all assets entrusted upon us; To report accurately and faithfully all activity as it occurs; 
To represent ourselves and our clients in a professional manner, second to none.
          </p>
      
        </div>
      </div> */}
   
    </div>
  </div>
</div>

       </div>

    )
}

export default About