import { Carousel } from 'antd';
// import carousel1 from "./carousel1.jpg"
// import carousel2 from "./carousel2.jpg"
// import carousel3 from "./carousel3.jpg"
// import carousel4 from "./carousel4.jpg"
import { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../components/firebaseConfig';


const contentStyle = {
  height: `${window.innerHeight > window.innerWidth ? '120px' : "480px"}`,
  color: '#fff',
  lineHeight: `${'160px'}`,
  textAlign: 'center',
  background: '#364d79',
};
const HomeCarousel = () => {
  const [images, setimages] = useState([])
  const getImages = ()=>{
    getDocs(collection(db,"Website/Homepage/Carousel")).then((snap)=>{
      snap.forEach((v)=>{
        setimages(prev=> prev.includes(v.data().image) ? [...prev] : [...prev,v.data().image] )
      })
    })
  }
  useEffect(() => {
    getImages()
  }, [])
  
  return (
  <Carousel autoplay>
    {images.map((v,i)=> <div>
      <h3 style={contentStyle} key={i}>
        <img src={v} width={"100%"}/>
      </h3>
    </div>)}
    
    {/* <div>
      <h3 style={contentStyle}>        <img src={carousel2} width={"100%"}/>

      
      </h3>
    </div>
    <div>
      <h3 style={contentStyle}>        <img src={carousel3} width={"100%"}/>

      
      
      </h3>
    </div>
    <div>
      <h3 style={contentStyle}>        <img src={carousel4} width={"100%"}/>

      
      </h3>
    </div> */}
  </Carousel>
)};
export default HomeCarousel;