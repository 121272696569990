import { Card, Col, Image, Row } from "antd"
import c2 from "./c2.jpeg"
// import al1 from "./Bangladesh-Export-Processing-Zone-Author-BEPZA.jpg"
// import al2 from "./Department-of-Inspection-for-Factories-and-Establishments-DIFE.jpg"
// import al3 from "./Dhaka-North-City-Corporation.jpg"
// import al4 from "./Registrar-of-Joint-Stock-Companies-And-Firms.jpg"
import { useEffect, useState } from "react"
import { collection, getDocs } from "firebase/firestore"
import { db } from "../../components/firebaseConfig"

const License = () => {
  const [affi, setaffi] = useState([])
  const getaffi = ()=>{
    getDocs(collection(db,"Website/Homepage/AffiliationLicense")).then((snap)=>{
      snap.forEach((v)=>{
        setaffi(prev=> prev.includes(v.data().image) ? [...prev] : [...prev,v.data().image] )
      })
    })
  }
  useEffect(() => {
    getaffi()
  }, [])

    return(
        <section className="relative  bg-blueGray-50">
        <div className="relative pt-32 pb-32 max-w-screen flex content-center items-center justify-center min-h-screen-75">
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover "
            style={{
              backgroundImage:
                `url(${c2})`,
            }}
          >
         
          </div>
          <div className="container relative mx-auto">
            <div className="items-center flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                <div className="pr-12">
                  <h1 className="hidden md:block text-gray-300 font-semibold text-5xl">
                  Affiliations & License

                  </h1>
               
                </div>
              </div>
            </div>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
            style={{ transform: "translateZ(0px)" }}>      
          </div>
        </div>
        <section className="pb-10 bg-blueGray-200 -mt-24">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap">
              <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                
                  <Row gutter={24}>
                    {affi.map((v,i)=> <Col span={window.innerHeight > window.innerWidth ? 24 :6} key={i}>
                      <Card  bordered={false}>
                        <Image src={v}/>
                      </Card>
                    </Col>)}
                  </Row>
                  </div>
                </div>
              </div>
            
            </div>
          
          </div>
        </section>
      </section>
      
    )
}

export default License