import { useEffect, useState } from "react"
// import e1 from "./e1.jpeg"
import { doc, getDoc } from "firebase/firestore"
import { db } from "../../components/firebaseConfig"

const History = () =>{ 
    const [history, sethistory] = useState({
      text:'',
      image:''
    })
    useEffect(() => {
      getDoc(doc(db, "Website/Homepage/History/yKyNiwLHG6pjkx7KA1OR")).then((snap) => {
        sethistory(snap.data())
      })
    }, [])
    

    return(
        <div className=" flex flex-col px-6 py-4 m-0 space-y-6  md:py-16 bg-themeblue md:flex-row md:items-center md:space-x-6">
  <div className="flex flex-col items-center w-full md:flex-row">
    <div className="flex justify-center order-2 mt-6 md:mt-0 md:space-y-3 md:flex-col">
      <button className="w-3 h-3 mx-2 bg-blue-500 rounded-full md:mx-0 focus:outline-none" />
      <button className="w-3 h-3 mx-2 bg-gray-300 rounded-full md:mx-0 focus:outline-none hover:bg-blue-500" />
      <button className="w-3 h-3 mx-2 bg-gray-300 rounded-full md:mx-0 focus:outline-none hover:bg-blue-500" />
      <button className="w-3 h-3 mx-2 bg-gray-300 rounded-full md:mx-0 focus:outline-none hover:bg-blue-500" />
    </div>
    <div className="max-w-lg md:mx-12 md:order-2">
      <h1 className="text-3xl font-medium tracking-wide  text-white md:text-4xl">
        History
      </h1>
      <p className="mt-4 text-gray-300">
        {history.text}
      {/* With a dream of seeing our beloved country secured, Twenty years ago we came up with a security service organization. Over a decade, Eagle Security and Services has earned your trust and confidence over the last Twenty years. We are providing the highest security to our citizens with enormous competence and dedication. We are working hard and innovating ourselves every day to improve and provide our customers with the highest level of security. We have over a decade of experience serving international and national organizations. */}

      </p>
   
    </div>
  </div>
  <div className="flex items-center justify-center w-full h-96 ">
    <img
      className="object-cover w-full h-full max-w-2xl rounded-md"
      src={history.image}
      alt="apple watch photo"
    />
  </div>
</div>

    )
}


export default History